import React, { useEffect } from 'react'
import bannerImage from '../assets/banner.jpg';
import './Banner.css';

export const Banner = () => {

  useEffect(()=> {
    document.title = `Welcome to the swamp!`;
  }, []);

  return (
    <section id="home" className='banner' style={{backgroundImage:`url(${bannerImage})`}}>
        <h1>FrowsyFrog</h1>
        <p>Game Programmer | Official Swamp!</p>
    </section>
  )
}
