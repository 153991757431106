import React from 'react'
import './PortfolioCard.css'
import { Link } from 'react-router-dom'

export const PortfolioCard = ({dir, imgDir, title, description}) => {
  return (
    <div className='portfolioCard'>
        <div className='wrapper'>
            <img src={imgDir} alt={title} loading='lazy'/>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
        <div className="button-wrapper">
          <Link to={"/portfolio/" + dir}>
            <button className="btn-fill">SEE MORE</button>
          </Link>
        </div>
    </div>
  )
}
