import React from 'react'
import "./Portfolio.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PortfolioCard } from './PortfolioCard/PortfolioCard';
import { portfolioData } from './portfolioData';

export const Portfolio = () => {
  const responsive = {
    extradesktop: {
      breakpoint: { max: 4000, min: 2200 },
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 2200, min: 1250 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1250, min: 520 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };


  return (
    <section id="portfolio" className='portfolio'>
        <h2>Portfolio</h2>
        <Carousel 
          swipeable={true}
          draggable={true}
          responsive={responsive}
          showDots={false}
          arrows={true}
          partialVisbile={false}     
        >
          {portfolioData.map(item =>
              <PortfolioCard key={item.id} dir={item.id} imgDir={item.imgDir} title={item.title} description={item.description}></PortfolioCard>
          )}
        </Carousel>
    </section>
  )
}
