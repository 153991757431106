import './App.css';
import NavBar from './components/NavBar';
import { Banner } from './components/Banner';
import { About } from './components/About';
import bgImage from './assets/bg.jpg';
import { Portfolio } from './components/Portfolio';
import { Contact } from './components/Contact';
import { Route, Routes, Navigate } from 'react-router-dom';
import { PortfolioPage } from './components/PortfolioPage';
import { ErrorPage } from './components/ErrorPage';
import {JOSCPrivacyPolicy} from './components/JOSCPrivacyPolicy';

export const Home = () => {
  return(
    <div>
      <Banner/>
      <About/>
      <Portfolio/>
      <Contact/>
    </div>
  );
}

function App() {
  return (
    <div className="App" style={{backgroundImage: `url(${bgImage})`}}>
      <NavBar/>
      <Routes>
        <Route path='/' element={Home()}/>
        <Route path='/portfolio/:id' exact element={<PortfolioPage/>}/>
        <Route path='/404' element={<ErrorPage/>} />
        <Route path='*' element={<Navigate to='/404'/>}/>
        <Route path='/JOSCprivacypolicy' element={<JOSCPrivacyPolicy/>}/>
      </Routes>
    </div>
  );
}

export default App;
