import React from "react";
import StarRating from "./StarRating";
import './SkillContainer.css';

const SkillItem = ({ src, alt, skill, qStars }) => {
  return (
    <div className="skill-item">
      <img loading="lazy" src={src} alt={alt} />
      <div className="overlay">
        <p>{skill}</p>
        <StarRating qStars={qStars}/>
      </div>
    </div>
  );
};

export default SkillItem;
