import { useParams, Navigate } from 'react-router-dom'
import { portfolioData } from './portfolioData';
import './PortfolioPage.css';
import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollToTop from './ScrollToTop';

export const PortfolioPage = () => {

  const {id} = useParams();
  const [width, setWindowWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }
  const responsive = {
    nodesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  window.addEventListener("resize", updateDimensions);

  let title = null;
  useEffect(()=> {
    document.title = `${title} | Portfolio`;
  }, [title]);

  const project = portfolioData.find(project => project.id === id);
  if(!project){
    return <Navigate to='/404'/>;
  }

  const {fullDescription, youtubeID, prevImgs, factsData, pageName, pageUrl} = project;
  title = project.title;

  return (
    <div className='portfolioPage'>
      <ScrollToTop/>
      <div className='project-contenetor'>
        <h2>{title}</h2>
        <a href={pageUrl} target='_blank' rel="noopener noreferrer">
          <button className="btn-fill">{pageName}</button>
        </a>
        <div className='project-columns'>
          <div className='left-project'>
            <div className='responsive-video'>
              <iframe
                title={id}
                allow='accelerometer; autoplay; clipboard-write; picture-in-picture'
                allowFullScreen
                src={`https://youtube.com/embed/${youtubeID}`}/>
            </div>
            {width > 1025 ? (
              <>
                {prevImgs.map(item =>
                  <img src={item} loading='lazy' alt={id}/>
                )}
              </>
            ) : (
              <Carousel 
                swipeable={true}
                draggable={true}
                responsive={responsive}
                showDots={false}
                arrows={true}
                partialVisibile={false}
                infinite={true}           
              >
                {prevImgs.map(item =>
                  <img key={id} src={item} loading='lazy' alt={id}/>
                )}
              </Carousel>
            )}
 
          </div>
          <div className='right-project'>
              <div className='facts-container'>
                <table>
                  <tbody>
                    {Object.entries(factsData).map(([key, value]) => (
                      <tr key={key}>
                        <td className='left-cell'><strong>{key}</strong></td>
                        <td className='right-cell'>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p>{fullDescription}</p>
          </div>
        </div>
      </div>
      
    </div>
  )
}
