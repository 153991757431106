import React from 'react'
import './About.css';
import faceImage from '../assets/face.png';
import unityLogo from '../assets/unitylogo.png';
import robloxLogo from '../assets/robloxlogo.png';
import photonLogo from '../assets/photonlogo.png';
import csharplogo from '../assets/csharplogo.png';
import pythonlogo from '../assets/pythonlogo.png';
import cpluslogo from '../assets/cpluslogo.png';
import blenderlogo from '../assets/blenderlogo.png';
import jslogo from '../assets/jslogo.png';
import SkillItem from './SkillContainer/SkillItem';

const ImageContainer = () => {
  return (
    <div className="skill-container">
      <SkillItem src={unityLogo} alt="Unity Logo" skill="Unity 3D & 2D" qStars = {5}/>
      <SkillItem src={csharplogo} alt="C# Logo" skill="C#" qStars={5}/>
      <SkillItem src={photonLogo} alt="Photon Logo" skill="Photon Pun" qStars={5}/>
      <SkillItem src={robloxLogo} alt="Roblox Logo" skill="Roblox Studio" qStars={4}/>
      <SkillItem src={pythonlogo} alt="Python Logo" skill="Python" qStars={4}/>
      <SkillItem src={cpluslogo} alt="C++ Logo" skill="C++" qStars={3}/>
      <SkillItem src={blenderlogo} alt="Blender Logo" skill="Blender" qStars={3}/>
      <SkillItem src={jslogo} alt="JavaScript Logo" skill="JavaScript" qStars={3}/>
    </div>
  );
};


export const About = () => {
  return (
    <section id="about" className='about'>
        <div className='about-top'>
            <h2>About</h2>
            <div className='about-profile'>
                <img src={faceImage} alt="FrowsyFrog Face"/>
                <p>Hallo! I'm FrowsyFrog, a 21-year-old indie game developer from Peru.
                  And since my childhood, I've been fascinated by storytelling and creating entertaining experiences.
                  That's why I started game development, beginning with games in PowerPoint and progressing to creating online multiplayer games using Unity.
                  <br/><br/>
                  I'm always on the lookout for new projects and acquiring additional skills to take my creations to the next level.
                  My main goal is to provide immersive and memorable experiences for players,
                  and I'm excited to share my work with as many people as possible.
                  <br/><br/>
                  If you have any questions, feel free to contact me. I'll be happy to assist you!
                </p>
            </div>
        </div>
        <div className='about-bottom'>
            <ImageContainer/>
        </div>
    </section>
  )
}
