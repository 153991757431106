import React, { useState, useRef, useEffect, useCallback } from 'react';
import "./NavBar.css";
import logoImage from "../assets/frowsyfroglogo.png";
import bubbleSound from "../assets/bubblesound.mp3"
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import greetingMessages from './greetingMessages.json';

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const [showBubble, setShowBubble] = useState(false);
  const [randomMessage, setRandomMessage] = useState('');
  const audioRef = useRef(null);
  const timeoutRef = useRef(null);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [show, setShow] = useState(true);

  
  const handleClick = () => {
    setClicked(prevClicked => !prevClicked);
  };

  const handleActiveLink = (link) => {
    if(isHomePage) return;
    setActiveLink(link);
  };

  const handleLogo = () => {
    const randomIndex = Math.floor(Math.random() * greetingMessages.length);
    const randomMessage = greetingMessages[randomIndex];
    setRandomMessage(randomMessage);
    audioRef.current.currentTime = 0;

    if (showBubble) {
      clearTimeout(timeoutRef.current);
    } else {
      setShowBubble(true);
    }
    audioRef.current.play();
    timeoutRef.current = setTimeout(() => setShowBubble(false), 3000);
  }

  const handleOutsideClick = useCallback((event) => {
    if (showBubble && !event.target.closest('.bubble') && !event.target.closest('.logo-container img')) {
      setShowBubble(false);
      clearTimeout(timeoutRef.current);
    }
  }, [showBubble]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (isHomePage){
      const sections = document.querySelectorAll('section');
      const sections_len = sections.length;
      let curSection = 0;

      let prevOffSetTop = sections[0].offsetTop;
      let nextOffSetTop = sections[1].offsetTop;

      const onScroll = () => {
        const scrollPos = window.scrollY + 390;
        if(scrollPos < prevOffSetTop){
          curSection--;
          prevOffSetTop = sections[curSection].offsetTop;
          nextOffSetTop = sections[curSection + 1].offsetTop;
          setActiveLink(sections[curSection].id);
        }
        else if(curSection + 1 < sections_len && scrollPos >= nextOffSetTop){
          curSection++;
          prevOffSetTop = sections[curSection].offsetTop;
          if(curSection + 1 < sections_len){
            nextOffSetTop = sections[curSection + 1].offsetTop;
          }
          setActiveLink(sections[curSection].id);
        }
      };
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [isHomePage]);

  useEffect(() => {
    const threshold = 25;
    let lastScrollY = window.scrollY;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.scrollY;
  
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      const show = scrollY > lastScrollY ? false : true;
      setShow(show);
      if(showBubble && !show && !clicked){
        setShowBubble(false);
        clearTimeout(timeoutRef.current);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
  
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
  
    window.addEventListener("scroll", onScroll);
 
    return () => window.removeEventListener("scroll", onScroll);
  }, [showBubble, clicked]);


  return (
    <header className={show || clicked ? '' : 'hide-navbar'}>
      <div className="logo-container" onClick={handleLogo}>
        <img src={logoImage} alt="FrowsyFrog Logo" width={49} />
        {showBubble && (
          <div className="bubble">
            <span>{randomMessage}</span>
          </div>
        )}
      </div>
      <nav id='navbar' className={clicked ? "open" : ""}>
        {['Home', 'About', 'Portfolio', 'Contact'].map((link) => (
          <HashLink
            key={link}
            smooth
            to={`/#${link.toLowerCase()}`}
            className={activeLink === link.toLowerCase() ? 'navlink active' : 'navlink'}
            onClick={() => handleActiveLink(link.toLowerCase())}
          >
            {link}
          </HashLink>
        ))}
      </nav>
      <div id="mobile" onClick={handleClick}>
        <i id="bar" className={clicked ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <audio ref={audioRef} src={bubbleSound} />
    </header>
  );
};

export default NavBar;
