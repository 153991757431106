import React from "react";

const StarRating = ({qStars}) => {
  const stars = [];

  for (let i = 0; i < qStars; i++) {
    stars.push(<i key={i} className="fa fa-star star"></i>);
  }

  for (let i = qStars; i < 5; i++) {
    stars.push(<i key={i} className="fa fa-star star star_empty"></i>);
  }

  return <div className="rating">{stars}</div>;
};

export default StarRating;
