import React, { useRef, useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export const Contact = () => {
  const [isSending, setIsSending] = useState(false);
  const [messageLength, setMessageLength] = useState(0);

  const form = useRef();
  const maxMessageLength = 500;

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const handleMessageChange = (event) => {
    setMessageLength(event.target.value.length);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    if (isSending){
      return;
    }

    const {user_name, user_email, message } = e.target.elements;

    if (!user_name.value || !user_email.value || !message.value) {
      toast.error('Please fill in all fields')
      return;
    }

    if(!validateEmail(user_email.value)){
      toast.error('Please enter a valid email address');
      return;
    }

    setIsSending(true);

    emailjs.sendForm('service_9qoe9xe', 'template_u3ii6t3', form.current, 'G0_3MJrCf7i_uXoD-')
      .then((result) => {
          toast.success('Email sent successfully!');
          console.log(result.text);
      }, (error) => {
          toast.error('Failed to send email. Please try again later.');
          console.log(error.text);
      })
      .finally(() => {
          setIsSending(false);
      });
  };

  return (
    <section id="contact" className='contact'>
        <h2>Contact</h2>
        <div className='contact-info'>
          <form 
            ref={form}
            onSubmit={sendEmail}
            className='contact-form'
          >
            <input type="text" name="user_name" placeholder='Name' className='input-info' disabled={isSending}/>
            <input type="email" name="user_email" placeholder='Email' className='input-info' disabled={isSending}/>
            <textarea name="message" placeholder='Message...' disabled={isSending} className='message-info' onChange={handleMessageChange} maxLength={maxMessageLength} />
            <div className="message-counter">{messageLength} / {maxMessageLength}</div>
            <input type="submit" value={isSending ? "Sending..." : "Send"} disabled={isSending} className='send-email-btn'/>
          </form>
          <div className='social-icons'>
          <a href="https://twitter.com/FrowsyF" target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.twitch.tv/frowsyfrog" target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-twitch"></i>
          </a>
          <a href="https://www.youtube.com/@frowsyfrog" target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a href="https://github.com/FrowsyFrog" target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-github"></i>
          </a>
          <a href="https://frowsyfrog.itch.io/" target='_blank' rel="noopener noreferrer">
            <i className="fa-brands fa-itch-io"></i>
          </a>
          </div>
        </div>
        <ToastContainer/>
    </section>
  )
}
